






































import { defineComponent, PropType } from '@vue/composition-api'
import { mapActions, mapGetters } from 'vuex'
import {
  IPaymentCondition,
  IOrganisation,
  ISubscription,
  IBid,
} from '@/interfaces'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import PaymentConditionForm from '@/components/organisation/PaymentConditionForm.vue'
import { Dialog } from '@/components/molecules/Dialog'
import { getDefaultFormItems } from '@/utils/PaymentConditionUtil/PaymentConditionUtil'

const NEW_PAYMENT_CONDITION = Symbol('NEW_PAYMENT_CONDITION')

type IPaymentConditionValue = string | null | typeof NEW_PAYMENT_CONDITION

interface ICustomDropdownItem {
  text?: string
  value?: IPaymentConditionValue
  smallText?: string
  divider?: boolean
}

export default defineComponent({
  name: 'PaymentConditionSelect',
  components: {
    CustomDialog: Dialog,
    PaymentConditionForm,
  },
  mixins: [PaymentConditionMixin],
  props: {
    value: {
      type: [Object, String] as PropType<IPaymentCondition | null>,
      default: null,
    },
    organisation: {
      type: Object as PropType<IOrganisation>,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    subscription: {
      type: Object as PropType<ISubscription>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousPaymentConditions: [] as IPaymentCondition[],
      NEW_PAYMENT_CONDITION,
      isLoading: true,
      selectedFormItems: null,
    }
  },
  computed: {
    ...mapGetters('organisation', ['paymentConditions']),
    modelValue: {
      get(): IPaymentConditionValue {
        return this.value
      },
      set(newValue: IPaymentConditionValue) {
        if (newValue === NEW_PAYMENT_CONDITION) {
          this.$refs.paymentConditionSelect.blur()
          this.selectedFormItems = getDefaultFormItems(this.organisation.id)
          this.$emit('input', null)
          return
        }
        this.$emit('input', newValue)
      },
    },
    paymentConditionItems() {
      const items = [] as ICustomDropdownItem[]
      if (this.previousPaymentConditions.length) {
        this.previousPaymentConditions.forEach(
          (condition: IPaymentCondition) => {
            items.push({
              text: this.getPaymentCondition(condition),
              value: condition.id,
            })
          }
        )
      }
      this.paymentConditions?.forEach((condition: IPaymentCondition) => {
        items.push({
          text: condition.name,
          smallText: this.getPaymentCondition(condition),
          value: condition.id,
        })
      })
      items.push({ divider: true })
      items.push({
        text: this.$t('organisation.paymentConditions.addNewPaymentCondition'),
        value: NEW_PAYMENT_CONDITION,
      })
      return items
    },
  },
  async created() {
    await this.fetchPaymentConditions({ organisationId: this.organisation.id })

    // Add all previous payment conditions from the subscription and its bids.
    if (this.subscription) {
      this.previousPaymentConditions.push(this.subscription.paymentCondition)

      if (this.subscription.bids) {
        this.subscription.bids.forEach((bid: IBid) => {
          if (bid.paymentCondition) {
            this.previousPaymentConditions.unshift(bid.paymentCondition)
          }
        })

        // Remove duplicate bid payment conditions.
        const previousPaymentConditionIds = this.previousPaymentConditions.map(
          (pc: IPaymentCondition) => pc.id
        )
        this.previousPaymentConditions = this.previousPaymentConditions.filter(
          (paymentCondition: IPaymentCondition, index: number) => {
            return (
              previousPaymentConditionIds.indexOf(paymentCondition.id) === index
            )
          }
        )
      }
    }

    this.isLoading = false
  },
  methods: {
    ...mapActions('organisation', ['fetchPaymentConditions']),
    selectPaymentCondition(paymentCondition: IPaymentCondition) {
      this.modelValue = paymentCondition.id
    },
    handleCloseDialog() {
      this.selectedFormItems = null
    },
  },
})
