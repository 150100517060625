





















































































































































































































































































































































































































































































import { PropType } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import MaterialTypeInput from '../common/MaterialTypeInput.vue'
import DateRangePicker from '../common/DateRangePicker.vue'
import MonthRangePicker from '../common/MonthRangePicker.vue'
import PriceInput from '../common/PriceInput.vue'
import StationSelect from '../common/StationSelect.vue'
import PaymentConditionSelect from '../common/PaymentConditionSelect.vue'
import {
  defaultFormItems,
  saveOfferFromFormItems,
  publishOffer,
  IFormItems,
} from '@/utils/OfferUtil/OfferUtil'
import MaterialFormatSelect from '@/components/common/MaterialFormatSelect.vue'
import ShippingIncludedSelect from '@/components/common/ShippingIncludedSelect.vue'
import NumberInput from '../common/NumberInput.vue'
import ImageGalleryUploadComponent from '../common/ImageGalleryUploadComponent.vue'
import {
  IFileListItem,
  IMaterialFormat,
  IMaterialFormatGroup,
  IMaterialType,
  IOffer,
  IPaymentCondition,
  IStation,
} from '@/interfaces'
import { getOfferImageUrls } from '@/utils/AssetUtil/AssetUtil'
import OfferSummary from '@/components/offer/OfferSummary.vue'
import { Dialog } from '@/components/molecules/Dialog'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { fetchMaterialFormats } from '@/utils/MaterialFormatUtil/MaterialFormatUtil'
import { fetchPaymentConditions } from '@/utils/PaymentConditionUtil/PaymentConditionUtil'
import { PrivateEmailList } from '@/components/common/PrivateEmailList'
import { fetchStations } from '@/utils/StationUtils/StationUtil'
import {
  OfferContractType,
  OriginType,
  OfferVisibility,
  OfferType,
} from '@/enums'
import { Checkbox } from '@/components/molecules/Checkbox'
import { Tooltip } from '@/components/common/Tooltip'
import { TextField } from '@/components/molecules/TextField'
import { Dropdown } from '@/components/molecules/Dropdown'

type IDropdownItemsRaw = [string, string | null]

const getDropdownItems = (entries: IDropdownItemsRaw[]) =>
  entries.map((entry) => ({
    text: entry[0],
    value: entry[1],
  }))

export default {
  name: 'OfferForm',
  components: {
    StationSelect,
    MaterialTypeInput,
    MaterialFormatSelect,
    PaymentConditionSelect,
    DateRangePicker,
    MonthRangePicker,
    PriceInput,
    NumberInput,
    ShippingIncludedSelect,
    ImageGalleryUploadComponent,
    OfferSummary,
    CustomDialog: Dialog,
    PrivateEmailList,
    Tooltip,
    Checkbox,
    Dropdown,
    TextField,
  },
  mixins: [ShippingMixin],
  inject: ['enums'],
  props: {
    formItemsData: {
      type: Object as PropType<IFormItems>,
      default: null,
    },
    hasBids: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    contractType: {
      type: String as PropType<OfferContractType>,
      default: OfferContractType.Spot,
    },
    isEdit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      currentMonth: moment().format('MMMM YYYY'),
      isPublishDialogVisible: false,
      uploadedImagesCount: 0,
      imageFileList: [],
      isUnitPriceActive: false,
      isUnitAmountActive: false,
      isSaveOfferLoading: false,
      isSaveAndPublishOfferLoading: false,
      isMaterialFormatGroupsLoading: true,
      materialFormatGroupId: null,
      unitAmountSteps: this.$appConfig.unitAmountSteps,
      formItems: this.formItemsData,
      offerTemplateId: null,
      offer: null,
      unitPriceIndexGroup: null,
      isTooltipVisible: true,
      rules: {
        offerType: {
          required: true,
          message: this.$t('offerForm.form.offerType.required'),
          trigger: 'change',
        },
        materialFormatGroupId: {
          required: true,
          message: this.$t('offerForm.form.materialFormatGroupId.required'),
          trigger: 'change',
        },
        paymentConditionId: {
          required: true,
          message: this.$t('offerForm.form.paymentCondition.required'),
          trigger: 'change',
        },
        sourceStation: {
          required: true,
          trigger: 'change',
          validator: (rule: object, value: IStation, callback: any) => {
            if (
              this.formItems.offerType === this.enums.OfferType.Buy &&
              !this.formItems.sourceStation &&
              !value
            ) {
              return callback(
                new Error(this.$t('offerForm.form.station.required') as string)
              )
            }

            return callback()
          },
        },
        destinationStation: {
          required: true,
          trigger: 'change',
          validator: (rule: object, value: IStation, callback: any) => {
            if (
              this.formItems.offerType === this.enums.OfferType.Sell &&
              !this.formItems.destinationStation &&
              !value
            ) {
              return callback(
                new Error(this.$t('offerForm.form.station.required') as string)
              )
            }

            return callback()
          },
        },
        includedShippingRadius: {
          trigger: 'blur',
          validator: (rule: object, value: number, callback: any) => {
            if (this.formItems.isShippingIncluded && value === 0) {
              return callback(
                new Error(
                  this.$t(
                    'offerForm.form.includedShippingRadius.required'
                  ) as string
                )
              )
            }

            return callback()
          },
        },
        unitPrice: {
          required: true,
          message: this.$t('offerForm.form.unitPrice.required'),
          trigger: 'blur',
        },
        unitPriceIndexGroup: this.isRecurringOffer
          ? {
              required: true,
              trigger: 'change',
              validator: (
                rule: object,
                value: string | null | undefined,
                callback: any
              ) => {
                if (this.unitPriceIndexGroup === undefined) {
                  return callback(
                    new Error(
                      this.$t('offerForm.form.unitPriceIndexGroup.required')
                    )
                  )
                }

                return callback()
              },
            }
          : undefined,
        unitPriceIndex: this.isRecurringOffer
          ? {
              trigger: 'change',
              validator: (
                rule: object,
                value: string | null | undefined,
                callback: any
              ) => {
                if (this.unitPriceIndexGroup && !value) {
                  return callback(
                    new Error(
                      this.$t(
                        'offerForm.form.unitPriceIndexName.required'
                      ) as string
                    )
                  )
                }

                return callback()
              },
            }
          : undefined,
        materialType: {
          required: true,
          message: this.$t('offerForm.form.materialType.required'),
          trigger: 'change',
        },
        materialFormat: {
          required: true,
          message: this.$t('offerForm.form.materialFormatId.required'),
          trigger: 'change',
        },
        deliveryDate: [
          {
            type: 'array',
            required: true,
            message: this.$t('offerForm.form.deliveryDateFrom.required'),
            trigger: 'change',
          },
          this.contractType === this.enums.OfferContractType.Spot
            ? {
                trigger: 'change',
                validator: (rule: object, value: number, callback: any) => {
                  const { deliveryDate, expiresAt } = this.formItems

                  if (deliveryDate.some((item: number) => !item)) {
                    return callback(
                      new Error(
                        this.$t(
                          'offerForm.form.deliveryDateFrom.required'
                        ) as string
                      )
                    )
                  }

                  if (expiresAt) {
                    if (
                      !moment(expiresAt)
                        .subtract(1, 'day')
                        .isSameOrBefore(moment.unix(deliveryDate[1]).toDate())
                    ) {
                      return callback(
                        new Error(
                          this.$t(
                            'offerForm.form.deliveryDateFrom.minValue'
                          ) as string
                        )
                      )
                    }
                  }

                  return callback()
                },
              }
            : {
                trigger: 'change',
                validator: (rule: object, value: number, callback: any) => {
                  const { deliveryDate } = this.formItems

                  if (deliveryDate.some((item: number) => !item)) {
                    return callback(
                      new Error(
                        this.$t(
                          'offerForm.form.deliveryDateFrom.required'
                        ) as string
                      )
                    )
                  }

                  if (
                    moment
                      .unix(deliveryDate[1])
                      .isSameOrBefore(
                        moment.unix(deliveryDate[0]).toDate(),
                        'month'
                      )
                  ) {
                    return callback(
                      new Error(
                        this.$t(
                          'offerForm.form.deliveryDateTo.minValue'
                        ) as string
                      )
                    )
                  }

                  return callback()
                },
              },
        ],
        guaranteedUnitAmount: {
          required: true,
          trigger: 'blur',
          validator: (rule: object, value: number, callback: any) => {
            if (Number(value) === 0) {
              return callback(
                new Error(
                  this.$t(
                    'offerForm.form.guaranteedUnitAmount.required'
                  ) as string
                )
              )
            } else if (value > this.$appConfig.guaranteedUnitAmount) {
              return callback(
                new Error(
                  this.$t('offerForm.form.guaranteedUnitAmount.maxValue', {
                    guaranteedUnitAmount: this.$appConfig.guaranteedUnitAmount,
                    unitType: this.enums.UnitType.Ton,
                  }) as string
                )
              )
            }

            return callback()
          },
        },
        expiresAt:
          this.contractType === this.enums.OfferContractType.Spot
            ? {
                required: true,
                trigger: 'change',
                validator: (rule: object, value: number, callback: any) => {
                  if (!value) {
                    return callback(
                      new Error(
                        this.$t('offerForm.form.expiresAt.required') as string
                      )
                    )
                  } else if (
                    !moment(value)
                      .subtract(1, 'day')
                      .isBefore(
                        moment.unix(this.formItems.deliveryDate?.[1]).toDate()
                      )
                  ) {
                    return callback(
                      new Error(
                        this.$t('offerForm.form.expiresAt.validator') as string
                      )
                    )
                  }

                  return callback()
                },
              }
            : {
                required: true,
                trigger: 'change',
                validator: (rule: object, value: number, callback: any) => {
                  if (!value) {
                    return callback(
                      new Error(
                        this.$t('offerForm.form.expiresAt.required') as string
                      )
                    )
                  } else if (
                    moment(value).isSameOrAfter(
                      moment.unix(this.formItems.deliveryDate[0]).toDate(),
                      'day'
                    )
                  ) {
                    return callback(
                      new Error(
                        this.$t('offerForm.form.expiresAt.contract') as string
                      )
                    )
                  }

                  return callback()
                },
              },
        unitAmount: {
          required: true,
          trigger: 'change',
          validator: (rule: object, value: number, callback: any) => {
            if (
              value === 0 &&
              this.formItems.offerType === this.enums.OfferType.Buy
            ) {
              return callback(
                new Error(
                  this.$t('offerForm.form.unitAmount.required', {
                    unitAmountSteps: `${this.$appConfig.unitAmountSteps} ${this.enums.UnitType.Ton}`,
                  }) as string
                )
              )
            }

            return callback()
          },
        },
        templateName: {
          trigger: 'blur',
          required: true,
          validator: (rule: object, value: string, callback: any) => {
            if (!value && this.formItems.isSaveTemplateChecked) {
              return callback(
                new Error(
                  this.$t('offerForm.form.templateName.required') as string
                )
              )
            }

            return callback()
          },
        },
        privateEmailList: {
          trigger: 'blur',
          required: true,
          validator: (rule: object, value: string, callback: any) => {
            if (
              this.formItems.offerVisibility === OfferVisibility.Private &&
              value?.length === 0
            ) {
              return callback(
                new Error(
                  this.$t('offerForm.form.inviteEmails.required') as string
                )
              )
            }

            return callback()
          },
        },
      },
      expiresAtPickerOptions: {
        disabledDate(date: Date) {
          return moment().isSameOrAfter(date)
        },
        firstDayOfWeek: this.$appConfig.date.firstDayOfWeek,
      },
      deliveryDatePickerOptions: {
        disabledDate(date: Date) {
          return moment().subtract(1, 'day').isAfter(date)
        },
      },
      contractPeriodPickerOptions: {
        disabledDate(date: Date) {
          const nextMonth = moment().add(1, 'month').startOf('month')
          return nextMonth.isAfter(date)
        },
      },
    }
  },
  computed: {
    ...mapGetters('offer', {
      offerTemplates: 'getOfferTemplates',
    }),
    ...mapGetters('materialFormatGroups', {
      materialFormatGroups: 'getMaterialFormatGroups',
    }),
    ...mapGetters('materialType', {
      materialTypes: 'getMaterialTypes',
    }),
    ...mapGetters('materialFormatGroups', {
      materialFormatGroups: 'getMaterialFormatGroups',
    }),
    ...mapGetters('priceIndex', ['priceIndices']),
    ...mapGetters('auth', {
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    isPriceIndexSelected(): boolean {
      return !!this.unitPriceIndexGroup
    },
    isMaterialFormatGroupDisabled() {
      return this.isMaterialFormatGroupsLoading
    },
    currentImageFileList(): Array<IFileListItem> {
      const offerImageUrls = getOfferImageUrls(this.formItems)

      if (this.formItems.images) {
        return this.formItems.images.map((item: string, index: number) => {
          return { name: item, url: offerImageUrls[index] }
        })
      }

      return []
    },
    selectedOfferTemplate() {
      return this.offerTemplates.find(
        (offerTemplate: IOffer) => this.offerTemplateId === offerTemplate.id
      )
    },
    isPrivateOffer() {
      return this.formItems.offerVisibility === OfferVisibility.Private
    },
    isRecurringOffer() {
      return this.contractType === this.enums.OfferContractType.Recurring
    },
    offerVisibilityItems() {
      return getDropdownItems([
        [this.$t('offerForm.form.standartOffer.label'), null],
        [this.$t('offerForm.form.privateOffer.label'), OfferVisibility.Private],
        [
          this.$t('offerForm.form.anonymousOffer.label'),
          OfferVisibility.Anonymous,
        ],
      ])
    },
    offerTypeItems() {
      return getDropdownItems([
        [this.$tc('common.offerType.sell', 1), OfferType.Sell],
        [this.$tc('common.offerType.buy', 1), OfferType.Buy],
      ])
    },
    materialIdItems() {
      return getDropdownItems([
        [
          this.$t('offerForm.form.materialId.placeholder'),
          '598e0b45-b962-40fe-8798-dffe06e532e5',
        ],
      ])
    },
    unitPriceIndexItems() {
      const indices = Object.keys(this.priceIndices)
      const regularItems = indices.map(
        (index) =>
          ([
            this.$t(`offerForm.form.unitPriceIndexGroup.${index}`),
            index,
          ] as unknown) as [string, string]
      )
      return getDropdownItems([
        [this.$t('offerForm.form.unitPriceIndexGroup.without'), null],
        ...regularItems,
      ])
    },
  },
  watch: {
    materialFormatGroupId: function () {
      this.calculateGuaranteedUnitAmount()
    },
    unitPriceIndexGroup: function () {
      if (!this.unitPriceIndexGroup && this.formItems.unitPriceIndex) {
        this.formItems.unitPriceIndex = null
      }
    },
    'formItems.paymentConditionId': async function (newValue: string) {
      if (newValue) {
        this.formItems.paymentCondition = await this.getPaymentCondition(
          newValue
        )
      }
    },
  },
  async created() {
    this.formItems.contractType = this.contractType
    await this.fetchOfferTemplates()
    await this.getMaterialFormatGroups()
    await this.loadPriceIndices()
    this.onMaterialFormatGroupChange()
    this.isMaterialFormatGroupsLoading = false
    this.unitPriceIndexGroup = this.formItems.unitPriceIndex?.group || null
  },
  methods: {
    ...mapActions('materialFormatGroups', ['getMaterialFormatGroups']),
    ...mapActions('offer', ['fetchOfferTemplates', 'saveOfferTemplate']),
    ...mapActions('materialFormatGroups', ['getMaterialFormatGroups']),
    ...mapActions('priceIndex', ['loadPriceIndices']),
    onSaveOfferTemplate() {
      if (this.formItems.isSaveTemplateChecked) {
        Object.assign(this.formItems, { name: this.formItems.templateName })
        this.saveOfferTemplate({
          organisationId: this.$store.state.auth.user.organisationId,
          offer: this.formItems,
        })
      }
    },
    getMaterialType() {
      return (
        this.materialTypes.find(
          (materialTypeItem: IMaterialType) =>
            this.selectedOfferTemplate.materialTypeId === materialTypeItem.id
        ) || null
      )
    },
    flashTooltip() {
      this.isTooltipVisible = true
      setTimeout(() => {
        this.isTooltipVisible = false
      }, 2000)
    },
    async getMaterialFormat(): Promise<IMaterialFormat | null> {
      try {
        const response = await fetchMaterialFormats(
          this.$store.state.auth.user.organisationId,
          {
            materialFormatGroupId: this.selectedOfferTemplate
              .materialFormatGroupId,
          }
        )
        const materialFormats = response.materialFormats
        return (
          materialFormats.find(
            (materialFormat: IMaterialFormat) =>
              this.selectedOfferTemplate.materialFormatId === materialFormat.id
          ) || null
        )
      } catch (e) {
        return null
      }
    },
    async getPaymentCondition(
      paymentConditionId = ''
    ): Promise<IPaymentCondition | null> {
      const id =
        paymentConditionId ?? this.selectedOfferTemplate?.paymentConditionId
      try {
        const response = await fetchPaymentConditions(
          this.$store.state.auth.user.organisationId
        )
        const paymentConditions = response.paymentConditions

        return (
          paymentConditions.find(
            (paymentCondition: IPaymentCondition) => id === paymentCondition.id
          ) || paymentConditions[0]
        )
      } catch (e) {
        return null
      }
    },
    async getStation(stationId: string): Promise<IStation | null> {
      try {
        const response = await fetchStations(
          this.$store.state.auth.user.organisationId
        )
        const stations = response.stations

        return (
          stations.find((station: IStation) => stationId === station.id) || null
        )
      } catch (e) {
        return null
      }
    },
    async onOfferTemplateSelect() {
      const {
        organisationId,
        materialId,
        materialTypeId,
        materialFormatGroupId,
        materialFormatId,
        offerType,
        unitPrice,
        unitAmount,
        paymentConditionId,
        sourceStationId,
        destinationStationId,
        isShippingIncluded,
        includedShippingRadius,
        guaranteedUnitAmount,
        privateEmailList,
        isPrivate,
        isAnonymous,
      } = this.selectedOfferTemplate

      this.formItems = {
        contractType: this.formItems.contractType,
        organisationId,
        materialId,
        materialTypeId,
        materialFormatGroupId,
        materialFormatId,
        materialType: this.getMaterialType(),
        materialFormat: await this.getMaterialFormat(),
        sourceStationId,
        destinationStationId,
        sourceStation: await this.getStation(sourceStationId),
        destinationStation: await this.getStation(destinationStationId),
        offerType,
        unitPrice,
        unitAmount,
        paymentConditionId,
        isShippingIncluded,
        includedShippingRadius,
        guaranteedUnitAmount,
        privateEmailList,
        originType: OriginType.Template,
        originId: this.offerTemplateId,
        offerVisibility: isPrivate
          ? this.enums.OfferVisibility.Private
          : isAnonymous
          ? this.enums.OfferVisibility.Anonymous
          : '',
      }

      await this.onMaterialFormatGroupChange()
    },
    closePublishDialog() {
      this.isPublishDialogVisible = false
      this.isSaveAndPublishOfferLoading = false
    },
    getOfferImageUrls,
    resetIncludedShippingRadius() {
      this.formItems.includedShippingRadius =
        defaultFormItems.includedShippingRadius
    },
    async onSubmit() {
      this.isSaveOfferLoading = true
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return (this.isSaveOfferLoading = false)
        }

        try {
          const offer = await saveOfferFromFormItems(this.formItems)
          await this.onSaveOfferTemplate()
          this.imageFileList = this.$refs.imageGalleryUploadComponent.getFileList()

          this.$refs.imageGalleryUploadComponent.submitUpload(offer.id)

          this.handleCloseDialog()
        } catch (error: any) {
          this.isSaveOfferLoading = false
          this.$message.error(error.message)
        }
      })
    },
    onSubmitAndPublish() {
      this.isSaveOfferLoading = true
      this.isSaveAndPublishOfferLoading = true
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return (this.isSaveAndPublishOfferLoading = false)
        }

        try {
          const formItems = this.offer
            ? Object.assign(this.formItems, { id: this.offer.id })
            : this.formItems
          this.offer = await saveOfferFromFormItems(formItems)
          await this.onSaveOfferTemplate()
          this.imageFileList = this.$refs.imageGalleryUploadComponent.getFileList()

          this.$refs.imageGalleryUploadComponent.submitUpload(this.offer.id)

          this.isPublishDialogVisible = true
        } catch (error: any) {
          this.isSaveAndPublishOfferLoading = false
          this.$message.error(error.message)
        }
      })
    },
    async onPublish() {
      try {
        await publishOffer(this.offer.id)

        this.handleCloseDialog(true)

        this.$emit('publish', this.offer.id)
      } catch (error: any) {
        this.isSaveAndPublishOfferLoading = false
        this.$message.error(error.message)
      }
    },
    onReset() {
      this.$emit('reset')
      this.offerTemplateId = null
      this.$refs.form.resetFields()
    },
    handleCloseDialog(isPublishing?: boolean) {
      const offerType = this.$t(
        `offerForm.form.offerType.value.${this.formItems.offerType}`
      )
      if (isPublishing) {
        this.isSaveAndPublishOfferLoading = false
      } else {
        this.isSaveOfferLoading = false
      }
      this.$message.success(
        this.$t(
          `offerForm.${
            isPublishing ? 'publishSuccessMessage' : 'successMessage'
          }.${this.formItems.id ? 'updated' : 'added'}`,
          { offerType }
        )
      )

      setTimeout(
        () => {
          this.$emit('close')
        },
        this.imageFileList?.length ? 500 : 0
      )
    },
    onImageUploadSuccess() {
      this.uploadedImagesCount++
      if (!this.offer) {
        this.handleCloseDialog()
      }
    },
    onMaterialFormatGroupChange() {
      this.$nextTick(() => {
        if (this.$refs.materialFormatSelect) {
          this.materialFormatGroupId = this.formItems.materialFormatGroupId
          this.$refs.materialFormatSelect.refresh()
        }
      })
    },
    calculateUnitAmount() {
      this.formItems.unitAmount =
        Math.ceil(this.formItems.unitAmount / this.unitAmountSteps) *
        this.unitAmountSteps
    },
    calculateGuaranteedUnitAmount() {
      const minGuaranteedUnitAmount = this.materialFormatGroups.find(
        (group: IMaterialFormatGroup) => group.id === this.materialFormatGroupId
      )?.minGuaranteedUnitAmount

      this.formItems.guaranteedUnitAmount = Math.max(
        this.formItems.guaranteedUnitAmount,
        Number(minGuaranteedUnitAmount) || 0
      )
    },
    showPriceLabel() {
      return this.isRecurringOffer && this.formItems.unitPriceIndex
        ? this.$t('')
        : 'buy'
    },
  },
} as any
