

















import {
  IMaterialFormat,
  IMaterialFormatGroup,
  IOrganisation,
  ISubscription,
} from '@/interfaces'
import { fetchMaterialFormatGroupsResource } from '@/modules/materialFormatGroup/resources'
import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import { fetchMaterialFormats } from '@/utils/MaterialFormatUtil/MaterialFormatUtil'

interface IMaterialFormatInfo {
  materialFormatGroup: IMaterialFormatGroup
  materialFormat: IMaterialFormat
}
interface ISubOption extends IMaterialFormatInfo {
  label: string
  value: string
}
interface IOption {
  label: string
  value: string
  materialFormatGroup: IMaterialFormatGroup
  children: ISubOption[]
}

interface IListItem {
  text: string
  value: string
  meta: IMaterialFormatInfo
}
interface IListHeader {
  header: string
}

export default defineComponent({
  name: 'MaterialFormatCascader',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    materialFormatGroup: {
      type: Object as PropType<IMaterialFormatGroup | null>,
      default: null,
    },
    materialFormat: {
      type: Object as PropType<IMaterialFormat | null>,
      default: null,
    },
    organisation: {
      type: Object as PropType<IOrganisation>,
      default: null,
    },
    subscription: {
      type: Object as PropType<ISubscription>,
      default: null,
    },
  },
  setup(props, { emit, root }) {
    const isLoading = ref(true)
    const options = ref<IOption[]>([])

    const label = computed(() => {
      if (!props.materialFormatGroup || !props.materialFormat) return '---'
      const groupNameKey = props.materialFormatGroup.name
      const groupName = root.$i18n.t(`common.materialFormats.${groupNameKey}`)
      return `${groupName} - ${props.materialFormat.name}`
    })
    const list = computed(() => {
      const list: Array<IListItem | IListHeader> = []
      options.value.forEach((option: IOption) => {
        list.push({ header: option.label })
        if (option.children) {
          option.children.forEach((subOption: ISubOption) => {
            list.push({
              text: subOption.label,
              value: subOption.value,
              meta: {
                materialFormat: subOption.materialFormat,
                materialFormatGroup: subOption.materialFormatGroup,
              },
            })
          })
        }
      })
      return list
    })
    const model = computed({
      get(): any {
        if (this.materialFormat && !this.isLoading) {
          return this.materialFormat.id
        }
        return null
      },
      set(values: IListItem) {
        emit(
          'change',
          values.meta.materialFormat,
          values.meta.materialFormatGroup
        )
      },
    })

    const refresh = async () => {
      isLoading.value = true

      const materialFormatGroups = await fetchMaterialFormatGroupsResource()
      const { materialFormats } = await fetchMaterialFormats(
        props.organisation.id
      )

      // Create format group options.
      const newOptions: Array<IOption> = materialFormatGroups.map(
        (materialFormatGroup) => {
          return {
            materialFormatGroup,
            label: String(
              root.$i18n.t(`common.materialFormats.${materialFormatGroup.name}`)
            ),
            value: materialFormatGroup.id,
            children: [],
          }
        }
      )

      // Create and push format options to group options.
      materialFormats.forEach((materialFormat) => {
        newOptions.forEach((option) => {
          if (option.value === materialFormat.materialFormatGroupId) {
            option.children.push({
              materialFormat,
              label: materialFormat.name,
              value: materialFormat.id,
              materialFormatGroup: option.materialFormatGroup,
            })
          }
        })
      })

      // Add all previous formats from the subscription and its bids.
      // Make sure to not add formats from the same organisation!
      if (props.subscription) {
        const subscription = props.subscription

        if (props.organisation.id !== subscription.organisationId) {
          const materialFormatGroupOption = newOptions.find(
            (option) =>
              option.materialFormatGroup.id ===
              subscription.materialFormatGroup.id
          )

          if (materialFormatGroupOption) {
            materialFormatGroupOption.children.unshift({
              label: subscription.materialFormat.name,
              value: subscription.materialFormat.id,
              materialFormatGroup: subscription.materialFormatGroup,
              materialFormat: subscription.materialFormat,
            })
          }
        }

        if (subscription.bids) {
          subscription.bids.forEach((bid: any) => {
            if (
              bid.materialFormat &&
              bid.materialFormatGroup &&
              props.organisation.id !== bid.organisationId
            ) {
              const bidMaterialFormatGroup = bid.materialFormatGroup
              const materialFormatGroupOption = newOptions.find(
                (option) =>
                  option.materialFormatGroup.id === bidMaterialFormatGroup.id
              )

              if (materialFormatGroupOption) {
                materialFormatGroupOption.children.unshift({
                  label: bid.materialFormat.name,
                  value: bid.materialFormat.id,
                  materialFormatGroup: bid.materialFormatGroup,
                  materialFormat: bid.materialFormat,
                })
              }
            }
          })

          // Remove duplicate bid formats.
          newOptions.forEach((option) => {
            const childrenValues = option.children.map((child) => child.value)

            option.children = option.children.filter((child, index) => {
              return childrenValues.indexOf(child.value) === index
            })
          })
        }
      }

      options.value = newOptions
      isLoading.value = false
    }

    onMounted(async () => {
      await refresh()
    })

    return { isLoading, options, label, list, model }
  },
})
