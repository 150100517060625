













import { mapGetters } from 'vuex'
import moment from 'moment'
import { formatDate } from '@/utils/utils'

export default {
  name: 'ConfirmDialog',
  props: {
    onInfoDialog: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', {
      user: 'getUser',
      organisationStatus: 'getOrganisationStatus',
    }),
    organisationValidationLimitDate() {
      return formatDate(
        moment(this.user.organisation.createdAt)
          .add(this.$appConfig.organisationValidationLimitDays, 'days')
          .unix()
      )
    },
  },
}
