










import { PropType } from 'vue'
import { Checkbox } from '../molecules/Checkbox'
import { OfferType } from '@/enums'

export default {
  name: 'OfferTypesCheckboxGroup',
  components: { Checkbox },
  inject: ['enums'],
  props: {
    value: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    userType: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  computed: {
    chosenTypes: {
      get(): string | null {
        return this.value || null
      },
      set(type: string) {
        this.$emit('input', type)
        this.$emit('change', type)
      },
    },
    allTypes() {
      return [OfferType.Buy, OfferType.Sell]
    },
  },
}
