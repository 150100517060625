


































import { ref, defineComponent, computed, PropType } from '@vue/composition-api'
import { RatingOverview } from '@/components/common/Rating/RatingOverview'
import { IRelationRating } from '@/interfaces'

export default defineComponent({
  name: 'RatingTabs',
  components: {
    RatingOverview,
  },
  props: {
    type: {
      type: String as PropType<string>,
      default: () => 'card',
    },
    publicRatings: {
      type: Object as PropType<IRelationRating>,
      default: () => null,
    },
    partnerRatings: {
      type: Object as PropType<IRelationRating>,
      default: () => null,
    },
  },
  setup(props) {
    const activeName = ref(props.publicRatings ? '0' : '1')
    const showPartnerRatings = computed(
      () => props.partnerRatings && props.partnerRatings.avgRating > 0
    )
    const showPublicRatings = computed(
      () => props.publicRatings && props.publicRatings.avgRating > 0
    )

    return { activeName, showPartnerRatings, showPublicRatings }
  },
})
