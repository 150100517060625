import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import {
  IOffer,
  IOrganisation,
  IStation,
  IUser,
  IMaterialType,
  IMaterialFormatGroup,
  IMaterialFormat,
  IPaymentCondition,
  IPriceIndex,
  IBid,
} from '@/interfaces'
import { BidStatus } from '@/enums'
import moment from 'moment'

export interface IFormItems {
  id?: string
  unitAmount: number | null
  unitPrice: number | null
  deliveryDate: [number | undefined, number | undefined]
  expiresAt: Date | null
  materialType: IMaterialType | null
  materialFormatGroup: IMaterialFormatGroup | null
  materialFormat: IMaterialFormat | null
  notes?: string | null
  paymentCondition?: IPaymentCondition | null
  guaranteedUnitAmount: number | null
  isShippingIncluded?: boolean | null
  includedShippingRadius: number | null
  sourceStation?: IStation | null
  destinationStation?: IStation | null
  unitPriceIndex: IPriceIndex | null
}

export interface ISaveQuery {
  id?: string
  offerId: string
  userId: string
  user?: IUser
  organisationId: string
  organisation?: IOrganisation
  bidStatus?: BidStatus
  unitAmount?: number
  isShippingIncluded?: boolean | null
  unitPrice?: number
  deliveryDateFrom?: number
  deliveryDateTo?: number
  expiresAt?: number
  materialTypeId?: string
  materialFormatGroupId?: string
  materialFormatId?: string
  guaranteedUnitAmount?: number
  paymentConditionId?: string
  notes?: string | null
  includedShippingRadius?: number | null
  sourceStationId?: string | null
  destinationStationId?: string | null
  unitPriceIndexId?: string | null
}

interface IAcceptQuery {
  id: string
  offerId: string
}

interface ICancelQuery {
  id: string
  offerId: string
}

const defaultFormItems: IFormItems = {
  unitAmount: null,
  unitPrice: 0,
  deliveryDate: [undefined, undefined],
  expiresAt: null,
  materialType: null,
  materialFormatGroup: null,
  materialFormat: null,
  guaranteedUnitAmount: null,
  isShippingIncluded: false,
  notes: '',
  paymentCondition: null,
  includedShippingRadius: 0,
  sourceStation: null,
  destinationStation: null,
  unitPriceIndex: null,
}

export const saveBidFromFormItems = async (
  formItems: IFormItems,
  offer: IOffer,
  bid?: IBid
): Promise<IBid> => {
  const saveQuery: ISaveQuery = {
    organisationId: '',
    userId: '',
    offerId: offer.id,
    notes: formItems.notes || defaultFormItems.notes,
  }

  if (bid) {
    saveQuery.id = bid.id
  }

  if (offer.isUnitAmountNegotiable && formItems.unitAmount) {
    saveQuery.unitAmount = Number(formItems.unitAmount)
  }

  if (offer.isShippingNegotiable) {
    saveQuery.isShippingIncluded = formItems.isShippingIncluded

    if (formItems.includedShippingRadius && formItems.isShippingIncluded) {
      saveQuery.includedShippingRadius = Number(
        formItems.includedShippingRadius
      )
    }
    if (formItems.sourceStation) {
      saveQuery.sourceStationId = formItems.sourceStation.id
    }
    if (formItems.destinationStation) {
      saveQuery.destinationStationId = formItems.destinationStation.id
    }
  }

  if (offer.isUnitPriceNegotiable && !isNaN(Number(formItems.unitPrice))) {
    saveQuery.unitPrice = Number(formItems.unitPrice)
  }

  if (offer.isMaterialTypeNegotiable && formItems.materialType) {
    saveQuery.materialTypeId = formItems.materialType.id
  }

  if (
    offer.isMaterialFormatNegotiable &&
    formItems.materialFormatGroup &&
    formItems.materialFormat
  ) {
    saveQuery.materialFormatGroupId = formItems.materialFormatGroup.id
    saveQuery.materialFormatId = formItems.materialFormat.id
  }

  if (offer.isDeliveryDateNegotiable && formItems.deliveryDate) {
    saveQuery.deliveryDateFrom = formItems.deliveryDate[0]
    saveQuery.deliveryDateTo = formItems.deliveryDate[1]
  }

  if (formItems.expiresAt) {
    saveQuery.expiresAt = moment.utc(formItems.expiresAt).unix()
  }

  if (
    offer.isGuaranteedUnitAmountNegotiable &&
    formItems.guaranteedUnitAmount
  ) {
    saveQuery.guaranteedUnitAmount = Number(formItems.guaranteedUnitAmount)
  }

  if (offer.isPaymentConditionNegotiable && formItems.paymentCondition) {
    saveQuery.paymentConditionId = formItems.paymentCondition.id
  }

  if (formItems.unitPriceIndex) {
    saveQuery.unitPriceIndexId = formItems.unitPriceIndex.id
  }

  return saveBid(saveQuery)
}

export const getFormItemsFromOffer = (
  offer: IOffer,
  organisationId: string,
  bid?: IBid
) => {
  let isShippingIncluded: boolean | null | undefined = null

  if (bid?.isShippingIncluded != null) {
    isShippingIncluded = bid.isShippingIncluded
  } else if (offer?.isShippingIncluded != null) {
    isShippingIncluded = offer.isShippingIncluded
  } else {
    isShippingIncluded = defaultFormItems.isShippingIncluded
  }

  const formItems: IFormItems = {
    isShippingIncluded,
    sourceStation: bid?.sourceStation || offer.sourceStation,
    destinationStation: bid?.destinationStation || offer.destinationStation,
    includedShippingRadius:
      bid && bid.includedShippingRadius
        ? bid.includedShippingRadius
        : offer.includedShippingRadius
        ? offer.includedShippingRadius
        : defaultFormItems.includedShippingRadius,
    unitAmount:
      bid && bid.unitAmount
        ? bid.unitAmount
        : offer.unitAmount
        ? offer.unitAmount
        : defaultFormItems.unitAmount,
    unitPrice:
      bid && bid.unitPrice !== undefined
        ? bid.unitPrice
        : offer.unitPrice !== undefined
        ? offer.unitPrice
        : defaultFormItems.unitPrice,
    guaranteedUnitAmount:
      bid && bid.guaranteedUnitAmount
        ? bid.guaranteedUnitAmount
        : offer.guaranteedUnitAmount
        ? offer.guaranteedUnitAmount
        : defaultFormItems.guaranteedUnitAmount,
    materialType:
      bid && bid.materialType
        ? bid.materialType
        : offer.materialType
        ? offer.materialType
        : defaultFormItems.materialType,
    materialFormatGroup:
      bid && bid.materialFormatGroup
        ? bid.materialFormatGroup
        : offer.materialFormatGroup
        ? offer.materialFormatGroup
        : defaultFormItems.materialFormatGroup,
    materialFormat:
      bid && bid.materialFormat
        ? bid.materialFormat
        : offer.materialFormat
        ? offer.materialFormat
        : defaultFormItems.materialFormat,
    deliveryDate: [
      bid && bid.deliveryDateFrom
        ? bid.deliveryDateFrom
        : offer.deliveryDateFrom || defaultFormItems.deliveryDate[0],
      bid && bid.deliveryDateTo
        ? bid.deliveryDateTo
        : offer.deliveryDateTo || defaultFormItems.deliveryDate[1],
    ],
    expiresAt: null,
    paymentCondition:
      bid && bid.paymentCondition
        ? bid.paymentCondition
        : offer.paymentCondition
        ? offer.paymentCondition
        : defaultFormItems.paymentCondition,
    unitPriceIndex:
      bid && bid.unitPriceIndex
        ? bid.unitPriceIndex
        : offer.unitPriceIndex
        ? offer.unitPriceIndex
        : defaultFormItems.unitPriceIndex,
  }

  return formItems
}

export const saveBid = async (query: ISaveQuery): Promise<IBid> => {
  const response = await HttpService.request({
    path: `/offers/${query.offerId}/bids/${query.id || ''}`,
    method: HttpMethod.Post,
    body: query,
  })

  return response as IBid
}

export const acceptBid = async (query: IAcceptQuery): Promise<IBid> => {
  const response = await HttpService.request({
    path: `/offers/${query.offerId}/bids/${query.id}/accept`,
    method: HttpMethod.Put,
  })

  return response as IBid
}

export const cancelBid = async (query: ICancelQuery): Promise<IBid> => {
  const response = await HttpService.request({
    path: `/offers/${query.offerId}/bids/${query.id}/cancel`,
    method: HttpMethod.Put,
  })

  return response as IBid
}

export const getDefaultFormItems = (): IFormItems => {
  return Object.assign({}, defaultFormItems)
}
