







import { OfferType, OfferVisibility } from '@/enums'
import { PropType } from 'vue'

export default {
  name: 'OfferTypeBadge',
  props: {
    offerType: {
      type: String as PropType<OfferType>,
      default: () => '',
    },
    offerVisibility: {
      type: String as PropType<OfferVisibility>,
      default: () => '',
    },
    badge: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    badgeColor() {
      return this.offerType === OfferType.Buy ? 'success' : 'error'
    },
    value() {
      return this.$tc(
        `common.offerType.${this.offerType}${this.offerVisibility}`,
        1
      )
    },
  },
}
