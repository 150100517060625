











import { formatPrice } from '@/utils/utils'
import { PropType } from 'vue'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'PriceInput',
  components: { TextField },
  props: {
    value: {
      type: Number as PropType<number>,
      default: 0,
    },
    name: {
      type: String as PropType<string>,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      dirtyPrice: formatPrice(this.value),
    }
  },
  computed: {
    price: {
      get() {
        return this.isActive ? this.dirtyPrice : formatPrice(this.value)
      },
      set(dirtyPrice: string) {
        this.dirtyPrice = dirtyPrice
      },
    },
  },
  methods: {
    onBlur() {
      const cents = this.getCentsFromDirtyPrice(this.dirtyPrice)
      this.isActive = false
      this.dirtyPrice = formatPrice(cents)

      this.$emit('input', cents)
    },
    onFocus() {
      this.isActive = true

      this.dirtyPrice =
        this.value || this.value === 0
          ? this.getDirtyPriceFromCents(this.value)
          : null
    },
    getDirtyPriceFromCents(cents: number): string | null {
      return (cents / 100).toString().replace('.', ',')
    },
    getCentsFromDirtyPrice(dirtyPrice: string | null): number | null {
      if (dirtyPrice === null || dirtyPrice === '') {
        return null
      }

      const centsString = dirtyPrice.replace(/[^-0-9.,]/g, '').replace(',', '.')

      return Math.round((parseFloat(centsString) || 0) * 100)
    },
  },
}
