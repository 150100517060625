

















import { defineComponent, PropType } from '@vue/composition-api'
import { IMaterialType } from '@/interfaces'
import { Dropdown } from '@/components/molecules/Dropdown'

export default defineComponent({
  name: 'MaterialTypeInput',
  components: { Dropdown },
  props: {
    value: {
      type: Object as PropType<IMaterialType | null>,
      default: null,
    },
    name: {
      type: String as PropType<string>,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      allMaterialTypes: [],
    }
  },
  computed: {
    materialType: {
      get(): string | null {
        return this.value ? this.value.code : null
      },
      set(materialTypeCode: string) {
        this.$emit(
          'input',
          this.allMaterialTypes.find((materialType: IMaterialType) => {
            return materialTypeCode === materialType.code
          }) || null
        )
      },
    },
  },
  async created() {
    await this.$store.dispatch('materialType/getMaterialTypes')
    this.allMaterialTypes = await this.$store.getters[
      'materialType/getMaterialTypes'
    ]
    this.isLoading = false
  },
  methods: {
    change() {
      this.$emit('change')
    },
  },
})
