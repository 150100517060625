










































































































































































































































































































































import { defineComponent, PropType } from '@vue/composition-api'
import moment from 'moment'
import { IOffer, IBid } from '@/interfaces'
import UserAvatar from '@/components/common/UserAvatar/UserAvatar.vue'
import AddressComponent from '@/components/common/AddressComponent.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import resources, { ITransaction } from '@/modules/transaction/resources'
import SummaryList from '@/components/common/SummaryList.vue'
import useOfferDetail from '@/components/offerDetail/composable'
import { formatPrice } from '@/utils/utils'

export default defineComponent({
  name: 'OfferSummary',
  components: {
    SummaryList,
    UserAvatar,
    AddressComponent,
    MarkdownComponent,
  },
  mixins: [ShippingMixin, PaymentConditionMixin],
  inject: ['enums'],
  props: {
    offer: {
      type: Object as PropType<IOffer>,
      required: true,
    },
    bid: {
      type: Object as PropType<IBid>,
      required: false,
      default: null,
    },
    actionTitle: {
      type: String as PropType<string>,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { isOfferTypeBuy, isStationInfoRevealed } = useOfferDetail(
      context,
      props.offer
    )

    return {
      isStationInfoRevealed,
      isOfferTypeBuy,
    }
  },
  data() {
    return {
      isAcceptOfferLoading: false,
      isTransactionPreCalculationLoading: true,
      initialFreeAmount: 250,
      summary: {
        fee: '',
        totalFee: '',
        transactions: [],
      },
      deliveryInfoTableHeaders: [
        { text: this.$t('offer.transactions.period'), value: 'period' },
        { text: this.$t('offer.transactions.amount'), value: 'amount' },
        { text: this.$t('offer.transactions.fee'), value: 'fee' },
      ],
    }
  },
  computed: {
    isOfferAnonymous() {
      return this.offer?.isAnonymous
    },
    formatPrice() {
      return formatPrice
    },
    buttonLabel() {
      return this.actionTitle
        ? this.actionTitle
        : this.bid
        ? this.$t('offer.acceptBidAction')
        : this.$t('offer.acceptOfferAction')
    },
    currentDate() {
      return moment().unix()
    },
    bidExpiresAt() {
      return moment(this.bid.expiresAt).unix()
    },
    unitType() {
      return this.offer.material.unitType
    },
    hasSameGuaranteedUnitAmount() {
      if (!this.bid || !this.offer?.isGuaranteedUnitAmountNegotiable) {
        return true
      }

      return this.offer?.guaranteedUnitAmount === this.bid?.guaranteedUnitAmount
    },
    hasSameMaterialType() {
      if (
        !this.bid ||
        !this.offer?.isMaterialTypeNegotiable ||
        !this.bid?.materialType
      ) {
        return true
      }

      return this.offer?.materialType?.code === this.bid?.materialType?.code
    },
    hasSameMaterialFormat() {
      if (
        !this.bid ||
        !this.offer?.isMaterialFormatNegotiable ||
        !this.bid?.materialFormat
      ) {
        return true
      }

      return this.offer?.materialFormat?.id === this.bid?.materialFormat?.id
    },
    hasSameUnitAmount() {
      if (!this.bid || !this.offer?.isUnitAmountNegotiable) {
        return true
      }

      return this.offer?.unitAmount === this.bid?.unitAmount
    },
    hasSameUnitPrice() {
      if (!this.bid || !this.offer?.isUnitPriceNegotiable) {
        return true
      }

      return this.offer?.unitPrice === this.bid?.unitPrice
    },
    hasSamePriceIndexPaperType() {
      if (!this.bid || !this.isContractTypeRecurring) {
        return true
      }

      return this.offer?.unitPriceIndex?.id === this.bid?.unitPriceIndex?.id
    },
    hasSameShipping() {
      if (!this.bid || !this.offer?.isShippingNegotiable) {
        return true
      }

      return (
        this.offer?.isShippingIncluded === this.bid?.isShippingIncluded &&
        this.offer?.includedShippingRadius === this.bid?.includedShippingRadius
      )
    },
    hasSameDeliveryDate() {
      if (!this.bid || !this.offer?.isDeliveryDateNegotiable) {
        return true
      }

      return (
        this.offer?.deliveryDateFrom === this.bid?.deliveryDateFrom &&
        this.offer?.deliveryDateTo === this.bid?.deliveryDateTo
      )
    },
    hasSamePaymentCondition() {
      if (
        !this.offer?.isPaymentConditionNegotiable ||
        !this.bid?.paymentCondition
      ) {
        return true
      }

      return this.offer?.paymentCondition?.id === this.bid?.paymentCondition?.id
    },
    hasSameUserId() {
      return (
        this.bid?.station?.organisationId ===
        this.$store.state.auth.user.organisation.id
      )
    },
    offerFee() {
      return `${formatPrice(this.offer?.materialType?.transactionFee)}/${
        this.unitType
      }`
    },
    isShippingIncluded() {
      return this.bid?.isShippingIncluded
    },
    isContractTypeRecurring() {
      return this.offer.contractType === this.enums.OfferContractType.Recurring
    },
    sourceStation() {
      return this.bid?.sourceStation || this.offer.sourceStation
    },
    destinationStation() {
      return this.bid?.destinationStation || this.offer.destinationStation
    },
    isOfferOwner() {
      return (
        this.$store.state.auth.user.organisation.id ===
        this.offer.organisationId
      )
    },
  },
  watch: {
    isVisible(newValue, oldValue) {
      // if it became visible
      if (!oldValue && newValue) {
        this.isAcceptOfferLoading = false
      }
    },
  },
  async created() {
    try {
      let transactions: ITransaction[] = []

      if (this.bid) {
        transactions = await resources.precalculateTransactionForBid(
          this.offer.id,
          this.bid
        )
      } else {
        transactions = await resources.precalculateTransactionForOffer(
          this.offer.id
        )
      }

      const transactionFee = transactions[0].calculation.unitFee
      const totalFee = transactions.reduce(
        (sum: number, transaction: ITransaction) =>
          sum + transaction.calculation.totalFee,
        0
      )

      this.summary = {
        fee: `${transactionFee && formatPrice(transactionFee)}/${
          this.unitType
        }`,
        totalFee: `${totalFee && formatPrice(totalFee)}/${this.unitType}`,
        transactions,
      } as {
        fee: string
        totalFee: string
        transactions: ITransaction[]
      }

      this.isTransactionPreCalculationLoading = false
    } catch (error) {
      this.isTransactionPreCalculationLoading = false
    }
  },
  methods: {
    printTrucksNumber(unitAmount: number, loadingWeight: number): number {
      return Math.floor(unitAmount / loadingWeight)
    },
    handleSubmitDialog() {
      this.isAcceptOfferLoading = true
      this.$emit('submit')
    },
    handleCloseDialog() {
      this.$emit('close-dialog')
    },
  },
})
