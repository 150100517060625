

















import { defineComponent, PropType, inject } from '@vue/composition-api'

export default defineComponent({
  name: 'RestrictedOrganisationButton',
  props: {
    buttonText: {
      type: String as PropType<string>,
      default: () => '',
    },
  },
  setup() {
    const enums = inject('enums')

    return {
      enums,
    }
  },
})
