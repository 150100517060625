import { OfferType } from '@/enums'

export const ShippingMixin = {
  methods: {
    getShippingLabel(
      isShippingIncluded?: boolean | null,
      includedShippingRadius?: number,
      offerType?: OfferType
    ) {
      const shipping = this.getShippingOptionLabel(
        isShippingIncluded,
        offerType
      )

      if (includedShippingRadius) {
        return `${shipping} ${this.$t('common.includedShippingRadius', {
          radius: includedShippingRadius,
        })}`
      } else {
        return shipping
      }
    },
    getShippingLabelAnonymous(
      isShippingIncluded?: boolean | null,
      includedShippingRadius?: number,
      offerType?: OfferType
    ) {
      const shipping = this.getShippingOptionLabel(
        isShippingIncluded,
        offerType
      )

      if (includedShippingRadius) {
        return `${shipping} ${this.$t('common.includedShippingRadius', {
          radius: includedShippingRadius,
        })}`
      } else {
        return shipping
      }
    },
    getBidShippingLabel(
      isShippingIncluded?: boolean | null,
      offerType?: OfferType
    ) {
      return this.getShippingOptionLabel(isShippingIncluded, offerType)
    },
    getShippingOptionLabel(
      isShippingIncluded?: boolean | null,
      offerType?: OfferType,
      isValueReversed?: boolean
    ) {
      if (isValueReversed && isShippingIncluded !== null) {
        isShippingIncluded = !isShippingIncluded
      }
      switch (isShippingIncluded) {
        case true:
          return this.$t(`common.isShippingIncluded.${offerType}.yes`)
        case false:
          return this.$t(`common.isShippingIncluded.${offerType}.no`)
        default:
          return this.$t('common.isShippingIncluded.all')
      }
    },
  },
}
