






































import { PropType } from 'vue'
import { EMAIL_REGEX_PATTERN } from '@/validation'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'PrivateEmailList',
  components: { TextField },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    placeholder: {
      type: String as PropType<String>,
      default: () => '',
    },
    isEdit: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
  },
  data() {
    return {
      formItems: {
        email: '',
      },
      initialEmails: this.value,
      emailList: this.value,
      rules: {
        email: {
          type: 'email',
          required: false,
          trigger: 'input',
          validator: (rule: object, value: string, callback: any) => {
            if (
              this.emailList.includes(value) ||
              !value.match(EMAIL_REGEX_PATTERN)
            ) {
              return callback(
                new Error(this.$t('offerForm.form.inviteEmails.requiredFormat'))
              )
            }

            return callback()
          },
        },
      },
    }
  },
  watch: {
    value() {
      this.emailList = this.value
    },
  },
  methods: {
    filterEmailList(email: string) {
      this.emailList = this.emailList.filter((i: string) => i !== email)
    },
    onRemove(email: string) {
      this.filterEmailList(email)
      this.$emit('input', this.emailList)
      this.$emit('change')
    },
    onAddEmail() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.$emit('input', [...this.emailList, this.formItems.email])
        this.$emit('change')
        this.formItems.email = ''
      })
    },
    onEdit(email: string) {
      this.filterEmailList(email)
      this.formItems.email = email
    },
    onBlur(event: any) {
      this.onAddEmail()

      if (!event.target.value && this.emailList.length > 0) {
        this.$refs.form.clearValidate('email')
      }
    },
    isEditable(email: string): boolean {
      return this.isEdit && this.initialEmails.includes(email)
    },
  },
}
