


















import { defineComponent, PropType, computed } from '@vue/composition-api'
import { RatingPopover } from '@/components/common/Rating/RatingPopover'
import { RatingInfoShort } from '@/components/common/Rating/RatingInfoShort'
import { RatingTabs } from '@/components/common/Rating/RatingTabs'
import { IRelationRating } from '@/interfaces'

export default defineComponent({
  name: 'RatingTabsPopover',
  components: {
    RatingPopover,
    RatingInfoShort,
    RatingTabs,
  },
  props: {
    publicRatings: {
      type: Object as PropType<IRelationRating>,
      default: () => ({}),
    },
    partnerRatings: {
      type: Object as PropType<IRelationRating>,
      default: () => ({}),
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const ratedTransactions = computed(
      () =>
        props.publicRatings?.ratedTransactions ||
        props.partnerRatings?.ratedTransactions ||
        0
    )
    const avgRating = computed(
      () =>
        props.publicRatings?.avgRating || props.partnerRatings?.avgRating || 0
    )
    const isPartner = computed(
      () =>
        !!(
          !props.publicRatings &&
          props.partnerRatings &&
          props.partnerRatings?.ratedTransactions
        )
    )

    return {
      ratedTransactions,
      avgRating,
      isPartner,
    }
  },
})
