
















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ICommonAddress } from '@/interfaces'

export default defineComponent({
  name: 'AddressComponent',
  props: {
    address: {
      type: Object as PropType<ICommonAddress | undefined>,
      default: undefined,
    },
    header: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const addressRows = computed(() => {
      if (!props.address) {
        return []
      }

      const rows = [] as Array<string>

      const {
        addressStreetName: streetName,
        addressStreetNumber: streetNumber,
        addressAdditional: additional,
        addressPostCode: postCode,
        addressCountyCode: countryCode,
        addressCity: city,
      } = props.address

      if (streetName || streetNumber) {
        rows.push(`${streetName} ${streetNumber || ''}`)
      }

      if (additional) {
        rows.push(additional)
      }

      if (postCode || city) {
        let composedCode = ''
        if (postCode && countryCode) {
          composedCode = `${countryCode}-${postCode}`
        } else if (postCode) {
          composedCode = postCode
        }

        rows.push(`${composedCode} ${city || ''}`)
      }

      return rows.filter(Boolean)
    })

    return { addressRows }
  },
})
