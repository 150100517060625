
















import { defineComponent, PropType } from '@vue/composition-api'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import { Dropdown } from '@/components/molecules/Dropdown'
import { OfferType } from '@/enums'
import { TextField } from '@/components/molecules/TextField'

export default defineComponent({
  name: 'ShippingIncludedSelect',
  components: { Dropdown, TextField },
  mixins: [ShippingMixin],
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    isAllOptionSelectable: {
      type: Boolean,
      default: true,
    },
    offerType: {
      type: String as PropType<OfferType>,
      default: null,
    },
    isBid: {
      type: Boolean,
      default: false,
    },
    isValueReversed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options() {
      const getOptionItem = (value: boolean | null) => ({
        text: this.getShippingOptionLabel(
          value,
          this.offerType,
          this.isValueReversed
        ),
        value: value,
      })
      const options = [getOptionItem(true), getOptionItem(false)]

      if (this.isAllOptionSelectable) {
        options.unshift(getOptionItem(null))
      }

      return options
    },
    isShippingIncluded: {
      get() {
        if (this.value === null || this.value === undefined) {
          return null
        }

        if (this.offerType === OfferType.Sell && !this.isBid) {
          return !this.value
        }

        return this.value
      },
      set(isShippingIncluded: boolean | null) {
        if (this.offerType === OfferType.Sell && !this.isBid) {
          this.$emit('input', !isShippingIncluded)
        } else {
          this.$emit('input', isShippingIncluded)
        }
        this.$emit('change')
      },
    },
  },
})
