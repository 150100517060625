
































import { mapGetters } from 'vuex'
import { Dialog } from '@/components/molecules/Dialog'
import StationForm from '@/components/organisation/StationForm.vue'
import { IStation } from '@/interfaces'
import {
  getDefaultFormItems,
  fetchStations,
} from '@/utils/StationUtils/StationUtil'

const NEW_STATION = Symbol('NEW_STATION')

type IStationValue = IStation | typeof NEW_STATION

interface IStationOption {
  text: string
  value: IStationValue
}

export default {
  name: 'StationSelect',
  components: {
    CustomDialog: Dialog,
    StationForm,
  },
  props: {
    // used for v-model
    value: {
      type: Object,
      default: null,
    },
    organisation: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedFormItems: null,
      debounce: 0,
      stationOptions: [] as IStationOption[],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      isOrganisationAdmin: 'getIsOrganisationAdmin',
      isGlobalAdmin: 'getIsGlobalAdmin',
    }),
    isAdmin() {
      return this.isGlobalAdmin || this.isOrganisationAdmin
    },
    stationName: {
      get() {
        if (this.value) {
          return this.value
        }
        return ''
      },
      set(stationValue: IStationValue | string) {
        if (typeof stationValue === 'string') {
          this.$emit('input', null)
          return
        }
        if (stationValue === NEW_STATION) {
          this.selectedFormItems = getDefaultFormItems(this.organisation.id)
          this.$emit('input', null)
          return
        }

        this.$emit('input', stationValue)
        this.$emit('change')
      },
    },
  },
  mounted() {
    this.fetchStations()
  },
  methods: {
    onClear() {
      this.$emit('input', null)
      this.$emit('change')
    },

    async fetchStations() {
      this.isLoading = true
      const response = await fetchStations(this.organisation.id)

      const stationOptions: Array<IStationOption> = response.stations.map(
        (station: IStation) => {
          return {
            value: station,
            text: this.stationOptionTemplate(station),
          }
        }
      )

      stationOptions.push({
        text: this.$t('organisation.stations.addNewStation').toString(),
        value: NEW_STATION,
      })

      this.stationOptions = stationOptions
      this.isLoading = false
    },
    stationOptionTemplate(station: IStation) {
      const { name, addressPostCode, addressCity, addressStreetName } = station

      return [
        name,
        `${addressPostCode} ${addressCity}`,
        addressStreetName,
      ].join(', ')
    },
    selectNewStation(station: IStation) {
      this.stationName = station.name
      this.$emit('input', station)
      this.$emit('change')
    },
    closeStationDialog() {
      this.selectedFormItems = null
    },
  },
}
