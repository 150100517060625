




import { PropType } from 'vue'
import { OfferStatus } from '@/enums'
import { Dropdown } from '@/components/molecules/Dropdown'

type IOfferStatusKey = keyof typeof OfferStatus

export default {
  name: 'OfferStatusSelect',
  components: { Dropdown },
  props: {
    value: {
      type: String as PropType<string>,
      default: '',
    },
    filter: {
      type: Array as PropType<Array<OfferStatus>>,
      default: () => [],
    },
  },
  computed: {
    offerStatus: {
      get(): string | null {
        return this.value || null
      },
      set(status: string) {
        this.$emit('input', status)
        this.$emit('change', status)
      },
    },
    filteredStatusItems() {
      const rawEntries = Object.keys(OfferStatus).filter((code) => {
        const key = OfferStatus[code as IOfferStatusKey]
        if (key) {
          return !this.filter.includes(key)
        }
        return false
      })
      return rawEntries.map((entry) => {
        const key = OfferStatus[entry as IOfferStatusKey]
        return {
          text: this.$t(`offer.status.${key}`),
          value: key,
        }
      })
    },
  },
}
