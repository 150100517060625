import { OfferVisibility } from '@/enums'
import { IOffer } from '@/interfaces'

export const OfferStatusMixin = {
  methods: {
    offerVisibilityType(offer: IOffer) {
      return offer.isAnonymous
        ? OfferVisibility.Anonymous
        : offer.isPrivate
        ? OfferVisibility.Private
        : ''
    },
  },
}
