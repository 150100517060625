















import moment from 'moment'
import { QUERY_DATE_FORMAT } from '@/modules/marketplace/getters'

export default {
  name: 'MonthRangePicker',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    pickerOptions: {
      type: Object,
      default: () => {},
    },
    startPlaceholder: {
      type: String,
      default: () => '',
    },
    endPlaceholder: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    options() {
      return Object.assign({}, this.pickerOptions, {
        firstDayOfWeek: this.$appConfig.date.firstDayOfWeek,
      })
    },
    dateRange: {
      get(): Date[] | null {
        if (!this.value || !this.value[0] || !this.value[1]) {
          return null
        }
        return [
          moment.unix(this.value[0]).toDate(),
          moment.unix(this.value[1]).toDate(),
        ]
      },
      set(dateRange: Date[] | null) {
        if (dateRange === null) {
          return this.$emit('input', [undefined, undefined])
        }
        this.$emit('input', [
          moment(dateRange?.[0], QUERY_DATE_FORMAT).unix(),
          moment(dateRange?.[1], QUERY_DATE_FORMAT).endOf('month').unix(),
        ])
      },
    },
  },
  methods: {
    change() {
      this.$emit('change')
    },
  },
}
