import {
  IFileListItem,
  IMaterialFormat,
  IMaterialType,
  IOffer,
  IPaymentCondition,
  IPriceIndex,
  IStation,
} from '@/interfaces'

import {
  OfferContractType,
  OfferStatus,
  OfferType,
  OfferVisibility,
} from '@/enums'
import moment from 'moment'
import { appConfig } from '@/config/config'
import { isNumber } from '@/utils/utils'
import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'

export interface ISaveImageQuery {
  id: string
  images: IFileListItem[]
}

export interface IFormItems {
  deliveryDate: [number | undefined, number | undefined]
  description: string
  expiresAt: string | Date | null
  guaranteedUnitAmount: number
  id: string | null
  images: string[] | null
  includedShippingRadius: number
  isDeliveryDateNegotiable: boolean
  isGuaranteedUnitAmountNegotiable: boolean
  isMaterialTypeNegotiable: boolean
  isMaterialFormatNegotiable: boolean
  isMaterialFormatGroupNegotiable: boolean
  isPaymentConditionNegotiable: boolean
  isShippingIncluded: boolean
  isShippingNegotiable: boolean
  isUnitAmountNegotiable: boolean
  isUnitPriceNegotiable: boolean
  materialFormat: IMaterialFormat | null
  materialFormatGroupId: string | null
  materialId: string
  materialType: IMaterialType | null
  offerStatus: OfferStatus
  offerType: OfferType | null
  contractType: OfferContractType
  originId?: string
  originType?: string
  paymentCondition: IPaymentCondition | null
  sourceStation: IStation | null
  destinationStation: IStation | null
  unitAmount: number
  unitPrice: number | null
  unitPriceIndex?: IPriceIndex
  offerVisibility?: string
  isPrivate?: boolean
  isAnonymous?: boolean
  isSaveTemplateChecked?: boolean
  privateEmailList?: string[] | null
  organisationId?: string
  materialTypeId?: string
  materialFormatId?: string
  destinationStationId?: string
  paymentConditionId?: string
}

export const defaultFormItems: IFormItems = {
  deliveryDate: [undefined, undefined],
  description: '',
  expiresAt: null,
  guaranteedUnitAmount: 0,
  id: null,
  images: null,
  includedShippingRadius: 0,
  isDeliveryDateNegotiable: true,
  isGuaranteedUnitAmountNegotiable: true,
  isMaterialTypeNegotiable: true,
  isMaterialFormatNegotiable: true,
  isMaterialFormatGroupNegotiable: true,
  isPaymentConditionNegotiable: true,
  isShippingIncluded: true,
  isShippingNegotiable: true,
  isUnitAmountNegotiable: true,
  isUnitPriceNegotiable: true,
  materialFormat: null,
  materialFormatGroupId: null,
  materialId: appConfig.paperMaterialId,
  materialType: null,
  offerStatus: OfferStatus.Draft,
  offerType: null,
  contractType: OfferContractType.Spot,
  paymentCondition: null,
  sourceStation: null,
  destinationStation: null,
  unitAmount: appConfig.unitAmountSteps,
  unitPrice: null,
  offerVisibility: undefined,
  privateEmailList: [],
  isPrivate: false,
  isAnonymous: false,
}

export interface ISaveQuery {
  deliveryDateFrom: number
  deliveryDateTo: number
  description: string | null
  expiresAt: number
  guaranteedUnitAmount: number
  id?: string
  includedShippingRadius: number | null
  isDeliveryDateNegotiable: boolean
  isGuaranteedUnitAmountNegotiable: boolean
  isMaterialTypeNegotiable: boolean
  isMaterialFormatNegotiable: boolean
  isMaterialFormatGroupNegotiable: boolean
  isPaymentConditionNegotiable: boolean
  isShippingIncluded: boolean | null
  isShippingNegotiable: boolean
  isUnitAmountNegotiable: boolean
  isUnitPriceNegotiable: boolean
  materialFormatGroupId: string
  materialFormatId: string
  materialId: string
  materialTypeId: string
  offerStatus: OfferStatus
  offerType: OfferType
  contractType: OfferContractType
  originType?: string
  originId?: string
  paymentConditionId: string
  sourceStationId?: string
  destinationStationId?: string
  unitAmount: number
  unitPrice: number | null
  unitPriceIndexId?: string
  privateEmailList?: string[]
  isPrivate?: boolean
  isAnonymous?: boolean
  images?: string[]
}

export const saveOfferFromFormItems = (
  formItems: IFormItems
): Promise<IOffer> => {
  if (
    !formItems.offerType ||
    !formItems.deliveryDate ||
    !formItems.materialFormatGroupId ||
    !formItems.materialFormat ||
    !formItems.materialType ||
    !formItems.paymentCondition ||
    (!formItems.sourceStation && formItems.offerType === OfferType.Buy) ||
    (!formItems.destinationStation && formItems.offerType === OfferType.Sell) ||
    !formItems.expiresAt
  ) {
    return Promise.reject(new Error('Form items where not validated correctly'))
  }
  const saveQuery: ISaveQuery = {
    deliveryDateFrom: formItems.deliveryDate[0] || 0,
    deliveryDateTo: formItems.deliveryDate[1] || 0,
    description: formItems.description || null,
    expiresAt: moment.utc(formItems.expiresAt).unix(),
    guaranteedUnitAmount: formItems.guaranteedUnitAmount,
    includedShippingRadius: formItems.includedShippingRadius,
    isDeliveryDateNegotiable: defaultFormItems.isDeliveryDateNegotiable,
    isGuaranteedUnitAmountNegotiable:
      defaultFormItems.isGuaranteedUnitAmountNegotiable,
    isMaterialTypeNegotiable: formItems.offerType === OfferType.Sell,
    isMaterialFormatNegotiable: defaultFormItems.isMaterialFormatNegotiable,
    isMaterialFormatGroupNegotiable:
      defaultFormItems.isMaterialFormatGroupNegotiable,
    isPaymentConditionNegotiable: defaultFormItems.isPaymentConditionNegotiable,
    isShippingIncluded: formItems.isShippingIncluded,
    isShippingNegotiable: defaultFormItems.isShippingNegotiable,
    isUnitAmountNegotiable: defaultFormItems.isUnitAmountNegotiable,
    isUnitPriceNegotiable: defaultFormItems.isUnitPriceNegotiable,
    materialFormatId: formItems.materialFormat.id,
    materialFormatGroupId: formItems.materialFormatGroupId,
    materialId: formItems.materialId,
    materialTypeId: formItems.materialType.id,
    offerStatus: formItems.offerStatus,
    offerType: formItems.offerType,
    contractType: formItems.contractType,
    paymentConditionId: formItems.paymentCondition.id,
    unitAmount: formItems.unitAmount,
    unitPrice: formItems.unitPrice,
    unitPriceIndexId: formItems.unitPriceIndex?.id,
    isPrivate: defaultFormItems.isPrivate,
    isAnonymous: defaultFormItems.isAnonymous,
  }

  if (formItems.id) {
    saveQuery.id = formItems.id
  }

  if (formItems.originType) {
    saveQuery.originType = formItems.originType
  }

  if (formItems.originId) {
    saveQuery.originId = formItems.originId
  }

  if (formItems.privateEmailList) {
    saveQuery.privateEmailList = formItems.privateEmailList
  }

  if (formItems.images) {
    saveQuery.images = formItems.images
  }

  if (formItems.offerVisibility === OfferVisibility.Private) {
    saveQuery.isPrivate = true
  } else if (formItems.offerVisibility === OfferVisibility.Anonymous) {
    saveQuery.isAnonymous = true
  }

  if (formItems.offerType === OfferType.Buy) {
    saveQuery.sourceStationId = formItems.sourceStation?.id
  } else {
    saveQuery.destinationStationId = formItems.destinationStation?.id
  }

  return saveOffer(saveQuery)
}

export const saveOffer = async (
  query: ISaveQuery | ISaveImageQuery
): Promise<IOffer> => {
  const response = await HttpService.request({
    path: `/offers/${query.id || ''}`,
    method: query.id ? HttpMethod.Put : HttpMethod.Post,
    body: query,
  })

  return response as IOffer
}

export const acceptOffer = async (offerId: string): Promise<IOffer> => {
  const response = await HttpService.request({
    path: `/offers/${offerId}/accept`,
    method: HttpMethod.Put,
  })

  return response as IOffer
}

export const publishOffer = async (offerId: string): Promise<IOffer> => {
  const response = await HttpService.request({
    path: `/offers/${offerId}/publish`,
    method: HttpMethod.Put,
  })

  return response as IOffer
}

export const cancelOffer = async (offerId: string): Promise<IOffer> => {
  const response = await HttpService.request({
    path: `/offers/${offerId}/cancel`,
    method: HttpMethod.Put,
  })

  return response as IOffer
}

export const getDefaultFormItems = (): IFormItems => {
  return Object.assign({}, defaultFormItems)
}

export const getFormItemsFromOffer = (offer: IOffer): IFormItems => {
  return {
    deliveryDate: [offer.deliveryDateFrom, offer.deliveryDateTo],
    description: offer.description || defaultFormItems.description,
    expiresAt: offer.expiresAt
      ? moment.unix(offer.expiresAt).toDate()
      : defaultFormItems.expiresAt,
    guaranteedUnitAmount: offer.guaranteedUnitAmount,
    id: offer.id,
    images: offer.images || [],
    includedShippingRadius:
      offer.includedShippingRadius || defaultFormItems.includedShippingRadius,
    isDeliveryDateNegotiable: offer.isDeliveryDateNegotiable,
    isGuaranteedUnitAmountNegotiable: offer.isGuaranteedUnitAmountNegotiable,
    isMaterialTypeNegotiable: offer.isMaterialTypeNegotiable,
    isMaterialFormatNegotiable: offer.isMaterialFormatNegotiable,
    isMaterialFormatGroupNegotiable: offer.isMaterialFormatGroupNegotiable,
    isPaymentConditionNegotiable: offer.isPaymentConditionNegotiable,
    isShippingIncluded: offer.isShippingIncluded,
    isShippingNegotiable: offer.isShippingNegotiable,
    isUnitAmountNegotiable: offer.isUnitAmountNegotiable,
    isUnitPriceNegotiable: offer.isUnitPriceNegotiable,
    offerVisibility: offer.isPrivate
      ? OfferVisibility.Private
      : offer.isAnonymous
      ? OfferVisibility.Anonymous
      : '',
    materialFormatGroupId: offer.materialFormat.materialFormatGroupId,
    materialFormat: offer.materialFormat,
    materialId: appConfig.paperMaterialId,
    materialType: offer.materialType,
    offerStatus: offer.offerStatus,
    offerType: offer.offerType,
    contractType: offer.contractType,
    paymentCondition:
      offer.paymentCondition || defaultFormItems.paymentCondition,
    privateEmailList: offer.privateEmailList,
    sourceStation: offer.sourceStation,
    destinationStation: offer.destinationStation,
    unitAmount: offer.unitAmount,
    unitPrice: isNumber(offer.unitPrice)
      ? offer.unitPrice
      : defaultFormItems.unitPrice,
    unitPriceIndex: offer.unitPriceIndex,
  }
}
