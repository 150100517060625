







































































































































































































































































































































































import { PropType } from 'vue'
import MaterialTypeInput from '@/components/common/MaterialTypeInput.vue'
import MaterialFormatCascader from '@/components/common/MaterialFormatCascader.vue'
import {
  saveBidFromFormItems,
  getFormItemsFromOffer,
  IFormItems,
} from '@/utils/BidUtil/BidUtil'
import PriceInput from '@/components/common/PriceInput.vue'
import DateRangePicker from '@/components/common/DateRangePicker.vue'
import ShippingIncludedSelect from '@/components/common/ShippingIncludedSelect.vue'
import MonthRangePicker from '@/components/common/MonthRangePicker.vue'
import StationSelect from '@/components/common/StationSelect.vue'
import moment from 'moment'
import OfferSummary from '@/components/offer/OfferSummary.vue'
import { Dialog } from '@/components/molecules/Dialog'
import { ShippingMixin } from '@/mixins/ShippingMixin'
import AddressComponent from '@/components/common/AddressComponent.vue'
import {
  IBid,
  IMaterialFormat,
  IMaterialFormatGroup,
  IPriceIndex,
} from '@/interfaces'
import PaymentConditionSelect from '@/components/common/PaymentConditionSelect.vue'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'
import { PaymentConditionMixin } from '@/mixins/PaymentConditionMixin'
import { Dropdown } from '@/components/molecules/Dropdown'
import { Tooltip } from '@/components/common/Tooltip'
import { TextField } from '@/components/molecules/TextField'

export default {
  name: 'BidForm',
  components: {
    MaterialTypeInput,
    MaterialFormatCascader,
    StationSelect,
    PriceInput,
    DateRangePicker,
    ShippingIncludedSelect,
    OfferSummary,
    CustomDialog: Dialog,
    AddressComponent,
    PaymentConditionSelect,
    MonthRangePicker,
    MarkdownComponent,
    Dropdown,
    Tooltip,
    TextField,
  },
  mixins: [ShippingMixin, PaymentConditionMixin],
  inject: ['enums'],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: String,
      required: true,
    },
    bid: {
      type: Object,
      required: false,
      default: null,
    },
    materialFormatGroups: {
      type: Array as PropType<IMaterialFormatGroup[]>,
      required: true,
    },
    priceIndices: {
      type: Object as PropType<IPriceIndex>,
      required: false,
      default: null,
    },
    offerRevealed: {
      type: Boolean as PropType<boolean>,
      default: () => true,
    },
  },
  data() {
    return {
      currentMonth: moment().format('MMMM YYYY'),
      isTooltipVisible: false,
      unitAmountSteps: this.$appConfig.unitAmountSteps,
      isOfferSummaryDialogVisible: false,
      isSaveBidLoading: false,
      formItems: getFormItemsFromOffer(
        this.offer,
        this.organisationId,
        this.bid
      ),
      materialFormatGroupId: null,
      unitPriceIndexGroup: null,
      deliveryDatePickerOptions: {
        disabledDate(date: Date) {
          return moment().subtract(1, 'day').isAfter(date)
        },
      },
      rules: {
        sourceStation: {
          required: true,
          trigger: 'change',
          validator: (rule: object, value: string, callback: any) => {
            if (!this.formItems.sourceStation) {
              return callback(
                new Error(this.$t('bidForm.validation.station.required'))
              )
            }

            return callback()
          },
        },
        destinationStation: {
          required: true,
          trigger: 'change',
          validator: (rule: object, value: string, callback: any) => {
            if (!this.formItems.destinationStation) {
              return callback(
                new Error(this.$t('bidForm.validation.station.required'))
              )
            }

            return callback()
          },
        },
        unitPrice: {
          required: true,
          message: this.$t('offerForm.form.unitPrice.required'),
          trigger: 'blur',
        },
        unitAmount: {
          required: true,
          trigger: 'blur',
          validator: (rule: object, value: string, callback: any) => {
            if (Number(value) === 0) {
              return callback(
                new Error(this.$t('bidForm.validation.unitAmount.required'))
              )
            }
            return callback()
          },
        },
        guaranteedUnitAmount: {
          trigger: 'blur',
          validator: (rule: object, value: number, callback: any) => {
            if (value) {
              if (Number(value) === 0) {
                return callback(
                  new Error(
                    this.$t('offerForm.form.guaranteedUnitAmount.required')
                  )
                )
              } else if (value > this.$appConfig.guaranteedUnitAmount) {
                return callback(
                  new Error(
                    this.$t('offerForm.form.guaranteedUnitAmount.maxValue', {
                      guaranteedUnitAmount: this.$appConfig
                        .guaranteedUnitAmount,
                      unitType: this.enums.UnitType.Ton,
                    })
                  )
                )
              }
            }
            return callback()
          },
        },
        unitPriceIndexGroup: this.isRecurringOffer
          ? {
              required: true,
              trigger: 'change',
              validator: (
                rule: object,
                value: string | null | undefined,
                callback: any
              ) => {
                if (this.unitPriceIndexGroup === undefined) {
                  return callback(
                    new Error(
                      this.$t('offerForm.form.unitPriceIndexGroup.required')
                    )
                  )
                }

                return callback()
              },
            }
          : undefined,
        unitPriceIndex: this.isRecurringOffer
          ? {
              trigger: 'change',
              validator: (
                rule: object,
                value: string | null | undefined,
                callback: any
              ) => {
                if (this.unitPriceIndexGroup && !value) {
                  return callback(
                    new Error(
                      this.$t('offerForm.form.unitPriceIndexName.required')
                    )
                  )
                }

                return callback()
              },
            }
          : undefined,
      },
      contractPeriodPickerOptions: {
        disabledDate(date: Date) {
          const nextMonth = moment().add(1, 'month').startOf('month')
          return nextMonth.isAfter(date)
        },
      },
    }
  },
  computed: {
    isPriceIndexSelected(): boolean {
      return !!this.unitPriceIndexGroup
    },
    isOfferOwner() {
      return (
        this.$store.state.auth.user.organisation.id ===
        this.offer.organisationId
      )
    },
    isRecurringOffer() {
      return this.offer?.contractType === this.enums.OfferContractType.Recurring
    },
    expiresAtPickerOptions() {
      return {
        disabledDate: (date: Date) => {
          if (moment(date).isAfter(moment().subtract(1, 'day'))) {
            return moment(date).isAfter(moment.unix(this.offer.expiresAt))
          } else {
            return true
          }
        },
        firstDayOfWeek: this.$appConfig.date.firstDayOfWeek,
      }
    },
    isOfferTypeBuy() {
      return this.offer.offerType === this.enums.OfferType.Buy
    },
    sourceStation() {
      return this.formItems.sourceStation || this.offer.sourceStation
    },
    destinationStation() {
      return this.formItems.destinationStation || this.offer.destinationStation
    },
    isStationInfoRevealed() {
      return this.offer?.bids?.some((bid: IBid) => bid.isShippingIncluded)
    },
    unitPriceIndexItems() {
      const indices = Object.keys(this.priceIndices)
      const regularItems = indices.map((index) => ({
        text: this.$t(`offerForm.form.unitPriceIndexGroup.${index}`),
        value: index,
      }))
      return [
        {
          text: this.$t('offerForm.form.unitPriceIndexGroup.without'),
          value: null,
        },
        ...regularItems,
      ]
    },
  },
  watch: {
    materialFormatGroupId: function () {
      this.calculateGuaranteedUnitAmount()
    },
    unitPriceIndexGroup: function () {
      if (!this.unitPriceIndexGroup && this.formItems.unitPriceIndex) {
        this.formItems.unitPriceIndex = null
      }
    },
  },
  async created() {
    this.unitPriceIndexGroup = this.formItems.unitPriceIndex?.group || null
    this.flashTooltip()
  },
  methods: {
    flashTooltip() {
      this.isTooltipVisible = true
      setTimeout(() => {
        this.isTooltipVisible = false
      }, 2000)
    },
    closeCreateBidDialog() {
      this.isOfferSummaryDialogVisible = false
    },
    normalizeFormItems(formItems: IFormItems) {
      const normalizedFormItems = Object.assign({}, formItems) as any

      if (formItems.deliveryDate) {
        normalizedFormItems.deliveryDateFrom = formItems.deliveryDate[0]
        normalizedFormItems.deliveryDateTo = formItems.deliveryDate[1]
      }

      return normalizedFormItems
    },
    validateBiddingForm() {
      this.$refs.form.validate(async (valid: boolean) => {
        if (!valid) {
          return
        }

        this.isSaveBidLoading = false
        this.isOfferSummaryDialogVisible = true
      })
    },
    async saveBid() {
      this.isSaveBidLoading = true

      try {
        await saveBidFromFormItems(this.formItems, this.offer, this.bid)

        this.isSaveBidLoading = false
        this.$emit('save')
        this.$emit('close-dialog')
      } catch (error: any) {
        this.isSaveBidLoading = false
        this.isOfferSummaryDialogVisible = false
        this.$message.error(error.message)
      }
    },
    onChangeMaterialFormat(
      materialFormat: IMaterialFormat,
      materialFormatGroup: IMaterialFormatGroup
    ) {
      this.formItems.materialFormatGroup = materialFormatGroup
      this.formItems.materialFormat = materialFormat
      this.materialFormatGroupId = materialFormatGroup?.id
    },
    calculateUnitAmount() {
      this.formItems.unitAmount =
        Math.ceil(this.formItems.unitAmount / this.$appConfig.unitAmountSteps) *
        this.$appConfig.unitAmountSteps
    },
    calculateGuaranteedUnitAmount() {
      const {
        materialFormatGroup: { id },
      } = this.formItems

      const minGuaranteedUnitAmount = this.materialFormatGroups.find(
        (group: IMaterialFormatGroup) =>
          group.id === id || group.id === this.materialFormatGroupId
      )?.minGuaranteedUnitAmount

      this.formItems.guaranteedUnitAmount = Math.max(
        this.formItems.guaranteedUnitAmount,
        Number(minGuaranteedUnitAmount) || 0
      )
    },
    closeParentDialog() {
      this.$emit('close-dialog')
    },
  },
}
