


























import { defineComponent, PropType } from '@vue/composition-api'
import {
  IOrganisation,
  IMaterialFormat,
  IMaterialFormatGroup,
} from '@/interfaces'
import {
  getDefaultFormItems,
  fetchMaterialFormats,
} from '@/utils/MaterialFormatUtil/MaterialFormatUtil'
import FormatForm from '@/components/organisation/FormatForm.vue'
import { Dialog } from '@/components/molecules/Dialog'
import { Dropdown } from '@/components/molecules/Dropdown'

const NEW_MATERIAL_FORMAT = Symbol('NEW_MATERIAL_FORMAT')

export default defineComponent({
  name: 'MaterialFormatSelect',
  components: {
    CustomDialog: Dialog,
    FormatForm,
    Dropdown,
  },
  props: {
    value: {
      type: Object as PropType<IMaterialFormat | null>,
      default: null,
    },
    materialFormatGroupId: {
      type: String,
      default: '',
    },
    materialFormatGroups: {
      type: Array as PropType<Array<IMaterialFormatGroup>>,
      default: [],
    },
    organisation: {
      type: Object as PropType<IOrganisation>,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      NEW_MATERIAL_FORMAT,
      materialFormats: [] as IMaterialFormat[],
      isLoading: true,
      selectedFormItems: null,
    }
  },
  computed: {
    materialFormat: {
      get(): string | null | typeof NEW_MATERIAL_FORMAT {
        if (this.value) {
          return this.isLoading ? this.value.name : this.value.id
        } else {
          return null
        }
      },
      set(materialFormatId: string | null | typeof NEW_MATERIAL_FORMAT) {
        if (materialFormatId === NEW_MATERIAL_FORMAT) {
          this.$refs.materialFormatSelect.blur()
          this.selectedFormItems = getDefaultFormItems(this.organisation.id)

          return
        }

        const materialFormat =
          this.materialFormats.find((format: IMaterialFormat) => {
            return materialFormatId === format.id
          }) || null

        this.$emit('input', materialFormat)
        this.$emit('change')
      },
    },
    materialFormatItems() {
      const items = this.materialFormats.map((format: IMaterialFormat) => ({
        text: format.name,
        value: format.id,
      }))
      items.push({
        text: this.$t('organisation.formats.addNewFormat'),
        value: NEW_MATERIAL_FORMAT,
      })
      return items
    },
  },
  async created() {
    await this.refresh()
  },
  methods: {
    async refresh() {
      if (this.materialFormatGroupId) {
        this.isLoading = true

        const response = await fetchMaterialFormats(this.organisation.id, {
          materialFormatGroupId: this.materialFormatGroupId,
        })

        this.materialFormats = response.materialFormats

        if (
          this.value &&
          !this.materialFormats
            .map((format: IMaterialFormat) => format.id)
            .includes(this.value.id)
        ) {
          this.$emit('input', null)
          this.$emit('change')
        }
      }

      this.isLoading = false
    },
    addMaterialFormat(materialFormat: IMaterialFormat) {
      if (materialFormat.materialFormatGroupId === this.materialFormatGroupId) {
        this.materialFormats.unshift(materialFormat)
        this.materialFormat = materialFormat.id
      }
    },
    closeDialog() {
      this.selectedFormItems = null
    },
  },
})
