


















import { PropType } from 'vue'
import ImageUploadComponent from '@/components/common/ImageUploadComponent.vue'
import { IFileListItem } from '@/interfaces'
import {
  getImageUploadOptions,
  deleteImage,
} from '@/utils/ImageUploadUtil/ImageUploadUtil'
import { FileAction, ImageUploadStatus } from '@/enums'

export default {
  name: 'ImageGalleryUploadComponent',
  components: {
    ImageUploadComponent,
  },
  inject: ['enums'],
  props: {
    id: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
    fileList: {
      type: Array as PropType<Array<IFileListItem>>,
      default: () => [],
    },
    removeImage: {
      type: Function as PropType<Promise<void | undefined>>,
      default: undefined,
    },
  },
  methods: {
    onSuccess() {
      this.$emit('onImageUploadSuccess')
    },
    async onImageRemove(file: File, fileList: Array<IFileListItem>) {
      let imageFileList: Array<IFileListItem> = []
      imageFileList = imageFileList.concat(fileList)
      imageFileList.splice(
        imageFileList.findIndex((item) => item.name === file.name),
        1
      )

      const { status } = file as any

      if (!this.id || status === ImageUploadStatus.Ready) return

      try {
        const imageDeleteOptions = await getImageUploadOptions(
          this.id,
          this.enums.ImageType.OfferDelete,
          file,
          FileAction.Delete
        )

        await deleteImage(imageDeleteOptions)

        if (this.onSuccess) {
          this.onSuccess()
        }
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    submitUpload(id?: string) {
      this.$refs.imageUploadComponent.submitUpload(id)
    },
    getFileList() {
      return this.$refs.imageUploadComponent.getFileList()
    },
  },
}
