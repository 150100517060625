import { computed, ref, SetupContext } from '@vue/composition-api'
import { IBid, IOffer } from '@/interfaces'
import enums from '@/enums'

export default function useOfferDetail(context: SetupContext, offer: IOffer) {
  const user = computed(() => context.root.$store.getters['auth/getUser'])
  const isOfferTypeBuy = ref(offer.offerType === enums.OfferType.Buy)
  const isOfferOwner = ref(user.value.organisation.id === offer.organisationId)
  const isStationInfoRevealed = computed(() =>
    isOfferOwner.value
      ? offer?.bids?.some((bid: IBid) => bid.isShippingIncluded)
      : true
  )

  return {
    isOfferTypeBuy,
    isStationInfoRevealed,
  }
}
